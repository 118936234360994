.card {
  flex: 0 0 300px;
  max-width: 100%;
  padding: 1rem;
  box-sizing: border-box;

  i {
    font-size: 1.4em;
    color: $docs-color-secondary;
  }

  small {
    font-size: 0.8rem;
    color: $docs-color-tabs-text-normal;
  }
}

.boxes.title-cards {
  margin: -1em;
  justify-content: normal;
}

.title-card {
  flex: 0 0 500px;
  max-width: 100%;
  padding: 1rem;
  box-sizing: border-box;

  &.small > h4 {
    margin-top: 0;
    font-size: 1rem;
  }

  .btn {
    width: 150px;
    text-align: left;
  }

  &.small {
    flex: 0 0 200px;
  }

  .badges {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0.8em 0;
    }
  }
}

.image-card {
  flex: 0 0 200px;
  text-decoration: none;
  color: $docs-color-text;

  &.details {
    flex-basis: 500px;
  }

  h5 {
    font-size: 1.2em;
    margin-bottom: 0;
  }

  .image {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    > img {
      max-height: 100%;
      filter: grayscale(100%);
      transition: all 0.3s ease;
    }
  }

  .card-details {
    padding: 1em;
    text-align: justify;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &:hover {
    color: $docs-color-secondary;

    .image > img {
      filter: none;
    }
  }
}
